import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as IconListToggle } from 'components/icons/small-arrow-left.svg';
import {CSSTransition} from 'react-transition-group';
import './Collapsible.scss';

function Collapsible ({
  open,
  setOpen,
  id,
  title = <>Title</>,
  titleClassName = '',
  body = <>Body</>,
  toggleIcon = null,
}) {
  return (
    <>
      <div
        id={id}
        className="pe-3 d-flex justify-content-between align-items-center collapsible-title-btn"
        onClick={() => setOpen(!open)}>
        <span className={titleClassName}>{title}</span>
        {toggleIcon || <IconListToggle style={{
          width: '12px',
          height: '12px',
          color: 'black',
          transform: open ? 'rotate(-90deg)' : 'rotate(90deg)',
          transition: 'transform 250ms ease-in-out',
        }} />}
      </div>
      <CSSTransition
        in={open}
        timeout={250}
        classNames="collapsible-wrapper"
      >
        <div className="collapsible-wrapper">
          <div className="collapsible">
            {body}
          </div>
        </div>
      </CSSTransition>
    </>
  );
}

Collapsible.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  id: PropTypes.string,
  title: PropTypes.string,
  titleClassName: PropTypes.string,
  body: PropTypes.element,
  toggleIcon: PropTypes.element,
};

export default Collapsible;