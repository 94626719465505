import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import {
  Configure,
  InstantSearch,
  SortBy,
} from 'react-instantsearch-dom';

import {algoliaCredentialsSelectors} from '../../features/algoliaCredentialsSlice';
import {CustomHits} from '../algolia/CustomHits.js';
import {CustomPagination} from '../algolia/CustomPagination.js';
import {NumberOfHits} from '../algolia/NumberOfHits.js';
import ProductCard from '../general/ProductCard.js';
import {WithAlgolia, useAlgoliaClient} from '../algolia/WithAlgolia';
import Hero2 from './StartPageAssets/header-c.png';
import { ReactComponent as IconSmallArrowLeft } from 'components/icons/small-arrow-left.svg';
import './OfferPage.scss';
import {FormattedMessage} from 'react-intl';

const OfferPage = () => {
  const algoliaClient = useAlgoliaClient();
  const productIndexName = useSelector(algoliaCredentialsSelectors.productIndex);
  const [searchState, setSearchState] = useState({});

  useEffect(() => {
    window.scrollTo(0,0);
  }, [searchState.page]);

  return (
    <WithAlgolia>
      {algoliaClient && productIndexName ?
        <div className="container-fluid px-md-3">
          <div className="row justify-content-center">
            <InstantSearch
              indexName={productIndexName}
              searchClient={algoliaClient}
              searchState={searchState}
              onSearchStateChange={setSearchState}
            >
              <Configure hitsPerPage={12} filters="isDiscount:true" />
              <div className="col-12 col-md-9">
                <div className="offer-page-hero mb-4" style={{backgroundImage: `url(${Hero2})`}}>
                  <h1>
                    <FormattedMessage
                      id="OfferPage.Angebote"
                      values={{
                        breakingLine: <br/>}}
                    />

                  </h1>
                </div>
                <div className="px-0">
                  <div className="d-flex justify-content-between align-items-center text-muted">
                    <NumberOfHits/>
                    <div className="sort-by">
                      <SortBy
                        id="sort-by-select"
                        defaultRefinement={productIndexName}
                        items={[
                          {value: productIndexName + '-offer', label: 'Angebot'},
                        ]}
                      />
                      <label htmlFor="sort-by-select">
                        <IconSmallArrowLeft />
                      </label>
                    </div>
                  </div>
                  <CustomHits hitComponent={Hit}/>
                  <div className="pagination mt-5">
                    <CustomPagination/>
                  </div>
                </div>
              </div>
            </InstantSearch>
          </div>
        </div>
        : 
        <div className="container-fluid px-md-3 text-center">
          loading...
        </div>}
    </WithAlgolia>
  );
};

function Hit(props) {
  return (
    <div className="col-xs-12 col-md-6 col-lg-6 col-xl-4 my-3">
      <ProductCard product={props.hit}/>
    </div>
  );
}

Hit.propTypes = {
  hit: PropTypes.object.isRequired,
};

export default OfferPage;
