import React from 'react';
import {ReactComponent as IconLogo} from 'components/icons/logo.svg';

export const SVGDefs = () => {
    
    return <div style={{
        position: 'absolute',
        left: 0,
        top: 0,
        height: 0,
        width: 0,
        overflow: 'hidden'
    }}>
        <IconLogo />
    </div>
}