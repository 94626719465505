import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import './CustomRangeSlider.scss';
// import { ReactComponent as IconListToggle } from 'components/icons/small-arrow-left.svg';

import { connectRange } from 'react-instantsearch-dom';

// Prerequisite: install rheostat@4
import Rheostat from 'rheostat';
// import 'rheostat/initialize';
import 'rheostat/css/slider.css';
import 'rheostat/css/slider-horizontal.css';
import {FormattedMessage} from 'react-intl';

const RangeSlider = ({
  min,
  max,
  currentRefinement,
  canRefine,
  refine,
  // title,
  unit,
  dots,
  texts = {},
  priceButtons = false,
}) => {
  const [stateMin, setStateMin] = React.useState(min);
  const [stateMax, setStateMax] = React.useState(max);

  useEffect(() => {
    if (canRefine) {
      setStateMin(currentRefinement.min);
      setStateMax(currentRefinement.max);
    }
  }, [canRefine, currentRefinement.min, currentRefinement.max]);

  const onChange = ({ values: [min, max] }) => {
    if (currentRefinement.min !== min || currentRefinement.max !== max) {
      refine({ min, max });
    }
  };

  const onValuesUpdated = ({ values: [min, max] }) => {
    setStateMin(min);
    setStateMax(max);
  };

  const dotIsActive = (percentage) => {
    const delta = max - min;
    return percentage - 0.05 > (stateMin - min)/delta
      &&
      percentage - 0.05 <= (stateMax - min)/delta;
  };

  return (
    <div className="list-group list-group-flush">
      {min === max ?
        <div className="list-group-item text-center">
          <i>
            <FormattedMessage
              id="Refinement.NoSelection"
            />
          </i>
        </div>
        :
        <div className="list">
          {priceButtons && <div className="px-3 mt-3">
            <button
              className="btn btn-sm btn-outline-dark rounded-pill me-2 mb-2"
              disabled={min > 10 || max < 10}
              onClick={() => {
                refine({ min: min, max: 10 });
              }}>
              <FormattedMessage
                id="Refinement.bis10"
              />
            </button>

            <button
              className="btn btn-sm btn-outline-dark rounded-pill me-2 mb-2"
              disabled={min > 10 || max < 15}
              onClick={() => {
                refine({ min: 10, max: 15 });
              }}>
              CHF 10 - CHF 15
            </button>

            <button
              className="btn btn-sm btn-outline-dark rounded-pill me-2 mb-2"
              disabled={min > 15 || max < 20}
              onClick={() => {
                refine({ min: 15, max: 20 });
              }}>
              CHF 15 - CHF 20
            </button>


            <button
              className="btn btn-sm btn-outline-dark rounded-pill me-2 mb-2"
              disabled={min > 20 || max < 20}
              onClick={() => {
                refine({ min: 20, max: max });
              }}>
              <FormattedMessage
                id="Refinement.ab20"
              />
            </button>
          </div>
          }
          <Rheostat
            min={min}
            max={max}
            values={[currentRefinement.min, currentRefinement.max]}
            onChange={onChange}
            onValuesUpdated={onValuesUpdated}
          >
            <div
              className="rheostat-marker rheostat-marker--large"
              style={{ left: 0 }}
            >
              <div className="rheostat-value">
                {'left' in texts ? texts.left : <>{stateMin}&nbsp;{unit}</>}
              </div>
            </div>
            <div
              className="rheostat-marker rheostat-marker--large"
              style={{ right: 0 }}
            >
              <div className="rheostat-value">
                {'right' in texts ? texts.right : <>{stateMax}&nbsp;{unit}</>}
              </div>
            </div>
          </Rheostat>
          {dots && <div className="dot-wrapper">
            <div className={dotIsActive(0.1) ? 'dot active' : 'dot'}></div>
            <div className={dotIsActive(0.2) ? 'dot active' : 'dot'}></div>
            <div className={dotIsActive(0.3) ? 'dot active' : 'dot'}></div>
            <div className={dotIsActive(0.4) ? 'dot active' : 'dot'}></div>
            <div className={dotIsActive(0.5) ? 'dot active' : 'dot'}></div>
            <div className={dotIsActive(0.6) ? 'dot active' : 'dot'}></div>
            <div className={dotIsActive(0.7) ? 'dot active' : 'dot'}></div>
            <div className={dotIsActive(0.8) ? 'dot active' : 'dot'}></div>
            <div className={dotIsActive(0.9) ? 'dot active' : 'dot'}></div>
            <div className={dotIsActive(1.0) ? 'dot active' : 'dot'}></div>
          </div>}
        </div>
      }
    </div>
  );
};

RangeSlider.propTypes = {
  min: PropTypes.number,
  max: PropTypes.number,
  currentRefinement: PropTypes.object,
  canRefine: PropTypes.bool,
  refine: PropTypes.func.isRequired,
  // title: PropTypes.string,
  unit: PropTypes.string,
  dots: PropTypes.bool,
  texts: PropTypes.object,
  priceButtons: PropTypes.bool,
};

export const CustomRangeSlider = connectRange(RangeSlider);