import React, {useEffect, useState} from 'react';

import ProductBadge from 'components/general/ProductBadge';
import {ReactComponent as IconArrowLeft} from 'components/icons/arrow-left.svg';
import {useSelector, useDispatch} from 'react-redux';
import {useHistory, useParams} from 'react-router-dom';
import {WithAlgolia, useAlgoliaProductIndex} from '../algolia/WithAlgolia';
import {authSelectors} from 'features/authSlice';
import HorizontalScrollWrapper from 'components/algolia/HorizontalScrollWrapper';
import './ProductDetailPage.scss';
import LoadingBottle from 'components/general/LoadingBottle';
import {ReactComponent as DownloadIcon} from 'components/icons/download.svg';

import {ReactComponent as IconThinCircle} from 'components/icons/thin-circle.svg';
import {ReactComponent as IconGlobe} from 'components/icons/globe.svg';
import {ReactComponent as IconHouse} from 'components/icons/house.svg';
import {ReactComponent as IconCircle} from 'components/icons/circle.svg';
import {ReactComponent as IconGrapes} from 'components/icons/grapes.svg';
import {ReactComponent as IconGlass} from 'components/icons/glass.svg';
import {ReactComponent as IconTaste} from 'components/icons/taste.svg';
import {ReactComponent as IconPercentage} from 'components/icons/percentage.svg';
import {ReactComponent as IconBottle} from 'components/icons/bottle.svg';
import {ReactComponent as IconCork} from 'components/icons/cork.svg';
import {ReactComponent as IconThermometer} from 'components/icons/thermometer.svg';
import {ReactComponent as IconCalendar} from 'components/icons/calendar.svg';
import {ReactComponent as IconQuotes} from 'components/icons/quotes.svg';
import {ReactComponent as Bottle} from 'components/icons/bottle.svg';
import {ReactComponent as IconAward} from 'components/icons/award.svg';
import {ReactComponent as IconForkKnife} from 'components/icons/fork-knife.svg';
import {ReactComponent as IconContainer} from 'components/icons/container.svg';
import {ReactComponent as IconAppellation} from 'components/icons/appellation.svg';


import bg from '../general/example_background.png';
import {FormattedMessage} from 'react-intl';
import AddToCart from 'components/general/Cart/AddToCart';
import { simmilarActions, simmilarSelectors } from 'features/simmilarSlice';
import { userSelectors } from 'features/userSlice';

import { SRLWrapper } from 'simple-react-lightbox';
import FavoritesHeart from 'components/general/FavoritesHeart';
import { generateWineMenuPdf } from 'features/pdf/generateWineMenuPdf';
import PDFPreview from 'features/pdf/PDFPreview';
import { ErrorBoundary } from 'components/general/ErrorBoundary';

const ProductListPage = () => {
  let {id} = useParams();
  const productIndex = useAlgoliaProductIndex();
  const productIndexReady = productIndex && productIndex.appId;

  const [loading, setLoading] = useState(true);
  const [product, setProduct] = useState({});

  const dispatch = useDispatch();
  const isLoggedIn = useSelector(authSelectors.loggedIn);
  const language = useSelector(userSelectors.language);
  const isSales = useSelector(authSelectors.isSalesUser);

  const flags = product.flags;

  const hasPrice = product.regular_price_per_unit != null && product.regular_price_per_bottle != null;

  const regularPricePerUnit = product.regular_price_per_unit;
  const salePricePerUnit = product.sale_price_per_unit || regularPricePerUnit;

  const pricePerBottleRegular = product.regular_price_per_bottle;
  const pricePerBottleSale = product.sale_price_per_bottle || pricePerBottleRegular;

  const pricePerDl = pricePerBottleSale / parseFloat(product.bottle_size) * 10;

  const hasDiscount = Math.abs(regularPricePerUnit - salePricePerUnit) >= 0.01;
  const discountPercentage = Math.floor(100 * (regularPricePerUnit - salePricePerUnit) / regularPricePerUnit);

  const discountEndDate = new Date(product.saleEnd);

  let history = useHistory();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (productIndex) {
      productIndex.getObject(id).then((result) => {
        setProduct(result);
        setLoading(false);
      });
    }
  }, [id, productIndex]);

  const simmilars = useSelector(simmilarSelectors.simmilars);
  const simmilarsRequest = useSelector(simmilarSelectors.simmilarsRequest);
  useEffect(() => {
    if (simmilarsRequest.status === 'success') {
      dispatch(simmilarActions.reset());
    }
  }, []);
  useEffect(() => {
    if (simmilarsRequest.status === 'idle' && product && product.objectID) {
      dispatch(simmilarActions.getSimmilarWines({
        authed: isLoggedIn,
        ids: [product.objectID],
      }));
    }
  }, [simmilarsRequest, product]);

  const [simmilarsProducts, setSimmilarsProducts] = useState(null);
  useEffect(() => {
    if (productIndexReady && !simmilarsProducts && simmilars) {
      productIndex.getObjects(simmilars).then((result) => {
        setSimmilarsProducts(result.results);
      });
    } else if (simmilars && simmilars.length == 0) setSimmilarsProducts([]);
  }, [productIndexReady, simmilars]);
  useEffect(() => {
    setSimmilarsProducts(null);
  }, [productIndex]);

  const [pdf, setPdf] = useState(null);
  const [pdfError, setPdfError] = useState(false);
  const [previewOpen, setPreviewOpen] = useState(false);

  const getPDF = (product) => {
    setPdfError(false);
    setPdf(null);

    if (product == null) {
      return;
    }

    generateWineMenuPdf(product,language)
      .then((response) => {
        setPdf(response.data.url);
        setPdfError(false);
      })
      .catch(function (error) {
        console.error('pdf', error);
        setPdf(null);
        setPdfError(true);
      });
  };

  return (
    <WithAlgolia>
      {previewOpen &&
        <div className="position-fixed" style={{zIndex: 1000, top:0, bottom:0, left:0, right:0}}>
          <ErrorBoundary>
            <PDFPreview
              open={previewOpen}
              pdf={pdf}
              error={pdfError}
              onClose={() => setPreviewOpen(false)}
            />
          </ErrorBoundary>
        </div>
      }

      <div className="product-detail">
        {loading ?
          <div className="d-flex justify-content-center">
            <LoadingBottle size={145}/>
          </div>
          : <>
            <div className="hero">
              <div className="top mb-3 back-button fw-bold">
                <span onClick={() => history.goBack()}>
                  <IconArrowLeft className="me-2"/>
                  <FormattedMessage
                    id="ProductDetailPage.Zurück"
                    values={{ts: Date.now()}}
                  />
                </span>
              </div>

              <div className="middle">
                <div className="left" style={{backgroundImage: `url(${bg})`}}>
                  {product.img_url ?
                    <SRLWrapper options={{
                      buttons: {
                        showAutoplayButton: false,
                        showCloseButton: true,
                        showDownloadButton: false,
                        showFullscreenButton: false,
                        showNextButton: false,
                        showPrevButton: false,
                        showThumbnailsButton: false,
                      },
                      thumbnails: {
                        showThumbnails: false,
                      }
                    }}>
                      <img src={product.img_url} alt={{
                        de: product.name,
                        fr: product.nameFr,
                        it: product.nameIt,
                      }[language]} className="preview-image"/>
                    </SRLWrapper>
                    :
                    <Bottle/>
                  }
                </div>
                <div className="right">
                  {
                    (isLoggedIn) &&
                    <div>
                      <FavoritesHeart wineId={product.objectID} />
                    </div>
                  }
                  <div className="d-flex">
                    {/* {product.outOfStock && <ProductBadge type="outOfStock" />} */}
                    {hasDiscount ? <ProductBadge type="discount" text={`${discountPercentage}%`}/> : <></>}
                    {flags && flags.length > 0 ?
                      flags.map((flag, index) => (
                        <ProductBadge key={index} type={flag}/>
                      )) : <></>
                    }
                  </div>
                  <h2>
                    {{
                      de: product.name,
                      fr: product.nameFr,
                      it: product.nameIt,
                    }[language]}
                  </h2>
                  {hasPrice && isLoggedIn ?
                    <>
                      <div className="price">
                        {hasDiscount ?
                          <>
                            <div className="now">
                              CHF {pricePerBottleSale.toFixed(2)}
                              <small>
                                {product.bottle_size}cl
                              </small>
                            </div>
                            <div className="normal">
                              CHF {pricePerBottleRegular.toFixed(2)}
                              /
                              {product.bottle_size}cl
                            </div>
                            {product.saleEnd && <div className="sale-end">
                              Aktion noch bis zum {`${discountEndDate.getDate()}.${discountEndDate.getMonth()+1}.${discountEndDate.getFullYear()}`} gültig
                            </div>}
                          </>
                          :
                          <>
                            <div className="now">
                              CHF {pricePerBottleSale.toFixed(2)}
                              <small>{product.bottle_size}cl</small>
                            </div>
                          </>
                        }
                        <div className="uvp">
                          <div>
                            Liefereinheit:&nbsp;
                            {{
                              de: product.delivery_unit,
                              fr: product.delivery_unitFr,
                              it: product.delivery_unitIt,
                            }[language]},
                            CHF {salePricePerUnit.toFixed(2)}
                          </div>
                        </div>
                        <div className="uvp">
                          <div>CHF {pricePerDl.toFixed(2)} / 1dl</div>
                        </div>
                      </div>
                    </>
                    :
                    <></>
                  }

                  {isLoggedIn && <div className="to-cart desktop">
                    <AddToCart
                      productId={product.objectID}
                      outOfStock={product.outOfStock}
                      name={{
                        de: product.name,
                        fr: product.nameFr,
                        it: product.nameIt,
                      }[language]}
                    />
                  </div>}
                </div>
              </div>

              <div className="quote">
                “{{
                  de: product.description,
                  fr: product.descriptionFr,
                  it: product.descriptionIt
                }[language]}”
              </div>

              {isLoggedIn && <div className="to-cart mobile">
                <AddToCart
                  productId={product.objectID}
                  outOfStock={product.outOfStock}
                  name={{
                    de: product.name,
                    fr: product.nameFr,
                    it: product.nameIt,
                  }[language]}
                />
              </div>}
            </div>

            <div className="d-flex">
              <div className="quote-desktop">
                <IconQuotes/>
                <p>{{
                  de: product.description,
                  fr: product.descriptionFr,
                  it: product.descriptionIt
                }[language]}</p>
              </div>

              <div className="information flex-grow-1">
                <h6>
                  <FormattedMessage
                    id="ProductDetailPage.info"
                    values={{ts: Date.now()}}
                  />

                </h6>
                <div className="flex-if-huge">
                  <ul>
                    <li>
                      <span className="icon">
                        <IconThinCircle />
                      </span>
                      <span className="title">
                        <FormattedMessage
                          id="ProductDetailPage.ArtNr"
                        />
                      </span>
                      <span className="value">{product.objectID}</span>
                    </li>
                    <li>
                      <span className="icon">
                        <IconGlobe/>
                      </span>
                      <span className="title">
                        <FormattedMessage
                          id="ProductDetailPage.land"
                          values={{ts: Date.now()}}
                        />
                      </span>
                      <span className="value">{{
                        de: product.country,
                        fr: product.countryFr,
                        it: product.countryIt
                      }[language]}</span>
                    </li>
                    <li>
                      <span className="icon">
                        <IconGlobe/>
                      </span>
                      <span className="title">
                        <FormattedMessage
                          id="ProductDetailPage.region"
                          values={{ts: Date.now()}}
                        />
                      </span>
                      <span className="value">{product.region}</span>
                    </li>
                    {product.appellation && <li>
                      <span className="icon">
                        <IconAppellation />
                      </span>
                      <span className="title">
                        <FormattedMessage
                          id="ProductDetailPage.Qualitätsbezeichnung"
                        />
                      </span>
                      <span className="value">{product.appellation}</span>
                    </li>}
                    <li>
                      <span className="icon">
                        <IconHouse/>
                      </span>
                      <span className="title">
                        <FormattedMessage
                          id="ProductDetailPage.produzent"
                          values={{ts: Date.now()}}
                        />
                      </span>
                      <span className="value">{product.producer}</span>
                    </li>
                    <li>
                      <span className="icon">
                        <IconCircle className={product.category}/>
                      </span>
                      <span className="title">
                        <FormattedMessage
                          id="ProductDetailPage.weintyp"
                          values={{ts: Date.now()}}
                        />
                      </span>
                      <span className="value">{{
                        de: product.category,
                        fr: product.categoryFr,
                        it: product.categoryIt
                      }[language]}</span>
                    </li>
                    <li>
                      <span className="icon">
                        <IconGrapes/>
                      </span>
                      <span className="title">
                        <FormattedMessage
                          id="ProductDetailPage.rebsorte"
                          values={{ts: Date.now()}}
                        />
                      </span>
                      <span className="value nowrap">{product.grapes.text?.replaceAll(', ', '\n') ?? ''}</span>
                    </li>
                    <li>
                      <span className="icon">
                        <IconGlass/>
                      </span>
                      <span className="title">
                        <FormattedMessage
                          id="ProductDetailPage.Süssegrad"
                          values={{ts: Date.now()}}
                        />
                      </span>
                      <span className="value">
                        <div className="dots">
                          <div className={`dot ${product.sweetness == 1 ? 'active' : ''}`}></div>
                          <div className={`dot ${product.sweetness == 2 ? 'active' : ''}`}></div>
                          <div className={`dot ${product.sweetness == 3 ? 'active' : ''}`}></div>
                          <div className={`dot ${product.sweetness == 4 ? 'active' : ''}`}></div>
                          <div className={`dot ${product.sweetness == 5 ? 'active' : ''}`}></div>
                          <div className={`dot ${product.sweetness == 6 ? 'active' : ''}`}></div>
                          <div className={`dot ${product.sweetness == 7 ? 'active' : ''}`}></div>
                          <div className={`dot ${product.sweetness == 8 ? 'active' : ''}`}></div>
                          <div className={`dot ${product.sweetness == 9 ? 'active' : ''}`}></div>
                          <div className={`dot ${product.sweetness == 10 ? 'active' : ''}`}></div>
                        </div>
                        <div className="desc">
                          <div>
                            <FormattedMessage id="ProductDetailPage.trocken"/>
                          </div>
                          <div>
                            <FormattedMessage id="ProductDetailPage.lieblich"/>
                          </div>
                        </div>
                      </span>
                    </li>
                    <li>
                      <span className="icon">
                        <IconTaste/>
                      </span>
                      <span className="title">
                        <FormattedMessage
                          id="ProductDetailPage.Schweregrad"
                          values={{ts: Date.now()}}
                        />
                      </span>
                      <span className="value">
                        <div className="dots">
                          <div className={`dot ${product.taste == 1 ? 'active' : ''}`}></div>
                          <div className={`dot ${product.taste == 2 ? 'active' : ''}`}></div>
                          <div className={`dot ${product.taste == 3 ? 'active' : ''}`}></div>
                          <div className={`dot ${product.taste == 4 ? 'active' : ''}`}></div>
                          <div className={`dot ${product.taste == 5 ? 'active' : ''}`}></div>
                          <div className={`dot ${product.taste == 6 ? 'active' : ''}`}></div>
                          <div className={`dot ${product.taste == 7 ? 'active' : ''}`}></div>
                          <div className={`dot ${product.taste == 8 ? 'active' : ''}`}></div>
                          <div className={`dot ${product.taste == 9 ? 'active' : ''}`}></div>
                          <div className={`dot ${product.taste == 10 ? 'active' : ''}`}></div>
                        </div>
                        <div className="desc">
                          <div>
                            <FormattedMessage
                              id="ProductDetailPage.leicht"
                              values={{ts: Date.now()}}
                            />
                          </div>
                          <div>
                            <FormattedMessage
                              id="ProductDetailPage.schwer"
                              values={{ts: Date.now()}}
                            />
                          </div>
                        </div>
                      </span>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <span className="icon">
                        <IconPercentage/>
                      </span>
                      <span className="title">
                        <FormattedMessage
                          id="ProductDetailPage.alkoholgehalt"
                          values={{ts: Date.now()}}
                        />
                      </span>
                      <span className="value">{product.alcoholContent}% vol</span>
                    </li>
                    <li>
                      <span className="icon">
                        <IconBottle/>
                      </span>
                      <span className="title">
                        <FormattedMessage
                          id="ProductDetailPage.flaschengrösse"
                          values={{ts: Date.now()}}
                        />
                      </span>
                      <span className="value">{product.bottle_size}cl</span>
                    </li>
                    <li>
                      <span className="icon">
                        <IconCork/>
                      </span>
                      <span className="title">
                        <FormattedMessage
                          id="ProductDetailPage.verschluss"
                          values={{ts: Date.now()}}
                        />
                      </span>
                      <span className="value">{{
                        de: product.closure,
                        fr: product.closureFr,
                        it: product.closureIt
                      }[language]}</span>
                    </li>
                    {product.drinking_temperature&&<li>
                      <span className="icon">
                        <IconThermometer/>
                      </span>
                      <span className="title">
                        <FormattedMessage
                          id="ProductDetailPage.trinktemperatur"
                          values={{ts: Date.now()}}
                        />
                      </span>
                      <span
                        className="value">{product.drinking_temperature[0]}° - {product.drinking_temperature[1]}°C
                      </span>
                    </li>}
                    <li>
                      <span className="icon">
                        <IconCalendar/>
                      </span>
                      <span className="title">
                        <FormattedMessage
                          id="ProductDetailPage.jahrgang"
                          values={{ts: Date.now()}}
                        />
                      </span>
                      <span className="value">{product.year}</span>
                    </li>
                    {product.ageingContainer && (
                      <li>
                        <span className="icon">
                          <IconContainer />
                        </span>
                        <span className="title">
                          <FormattedMessage id="ProductDetailPage.Lagerung"/>
                        </span>
                        <span className="value">
                          <FormattedMessage id="ProductDetailPage.Lagerung.Eichenfass"/>
                          {product.ageingTime && (
                            <><br />{product.ageingTime} <FormattedMessage id="ProductDetailPage.Lagerzeit.Monate"/></>
                          )}
                        </span>
                      </li>
                    )}
                    {product.awards?.length > 0 && <li>
                      <span className="icon">
                        <IconAward />
                      </span>
                      <span className="title">
                        <FormattedMessage
                          id="ProductDetailPage.Award"
                        />
                      </span>
                      <span className="value">{{
                        'de': <>{product.awards.map(item => <>{item}<br /></>) || '-'}</>,
                        'fr': <>{product.awardsFr.map(item => <>{item}<br /></>) || '-'}</>,
                        'it': <>{product.awardsIt.map(item => <>{item}<br /></>) || '-'}</>,
                      }[language]}</span>
                    </li>}
                    <li>
                      <span className="icon">
                        <IconForkKnife />
                      </span>
                      <span className="title">
                        <FormattedMessage
                          id="ProductDetailPage.PasstZu"
                        />
                      </span>
                      <span className="value">{{
                        'de': <>{product.suitableFood.map(item => <>{item}<br /></>) || '-'}</>,
                        // translated suitable foods are empty for now...
                        'fr': <>{product.suitableFood.map(item => <>{item}<br /></>) || '-'}</>,
                        'it': <>{product.suitableFood.map(item => <>{item}<br /></>) || '-'}</>,
                      }[language]}</span>
                    </li>
                    <li>
                      <i>
                        <FormattedMessage
                          id="ProductDetailPage.jahrgangVorb"
                          values={{ts: Date.now()}}
                        />
                      </i>
                    </li>
                  </ul>
                </div>
                {isSales &&
                  <div className='w-auto my-3'>
                    <button
                      onClick={() => {
                        setPreviewOpen(true);
                        getPDF(product);
                      }}
                      className="btn btn-outline-primary"
                    >
                      <DownloadIcon className="" />
                      <FormattedMessage
                        id="ProductDetailPage.Factsheet"
                      />
                    </button>
                  </div>
                }
              </div>
            </div>

            <div className="simmilar">
              <div className="mt-5">
                <h5>
                  <FormattedMessage
                    id="ProductDetailPage.KundKauft"
                    values={{ts: Date.now()}}
                  />
                </h5>
                <HorizontalScrollWrapper products={simmilarsProducts ? simmilarsProducts : []} placeholder="Keine Produkte gefunden" />
              </div>
            </div>
          </>}
      </div>
    </WithAlgolia>
  );
};

export default ProductListPage;
