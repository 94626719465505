import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { ReactComponent as IconClose } from 'components/icons/close.svg';
import {
  addToCurrentCart,
  removeFromCurrentCart,
} from 'features/cartSlice';
import { ReactComponent as Bottle } from 'components/icons/bottle.svg';
import './CartItem.scss';

const CartItem = ({
  quantity,
  product,
  showImage = false,
  changeable = false,
  setOpen = null,
  archived = false,
}) => {
  const dispatch = useDispatch();

  const detailPageLink = '/product/' + product.objectID;

  const hasPrice = product.regular_price_per_unit != null;
  const regularPricePerUnit = product.regular_price_per_unit;
  const salePricePerUnit = product.sale_price_per_unit || regularPricePerUnit;
  // const hasDiscount = Math.abs(regularPricePerUnit - salePricePerUnit) >= 0.01;
  const salePriceTotal = salePricePerUnit * quantity;

  let warning = product.saleEnd && new Date(product.saleEnd) < new Date() ? 'Promotion für den Liefertermin abgelaufen' : null;
  warning = product.outOfStock ? 'Ausverkauft' : null;

  return (
    <div className="cart-item">
      {showImage &&
        <div className="image-wrapper">
          {product.img_url ?
            <img src={product.img_url} alt={product.name} />
            :
            <Bottle />
          }
        </div>
      }

      {changeable ||
        <div className="quantity-wrapper">
          {quantity}&times;
        </div>
      }
      <div className="text-wrapper">
        <div className="title">
          {!archived && changeable ?
            <Link to={detailPageLink} onClick={setOpen && setOpen(false)}>
              {product.name || <i>(Artikel-Nr.: {product.objectID})</i>}
            </Link>
            :
            product.name || <i>(Artikel-Nr.: {product.objectID})</i>
          }&nbsp;
        </div>

        {warning && <div className="warning">
          {warning}
        </div>}

        {archived ?
          <div className="warning">
            Nicht mehr verfügbar
          </div>
          :
          <>
            {hasPrice && <div className="text d-flex justify-content-between">
              <span className="text-muted">
                CHF {salePricePerUnit.toFixed(2)}
                /
                {product.delivery_unit}
                {product.deposit && <><br /><span className="text-muted">
                  (zzgl. Gebindepfand)
                </span></>}
              </span>

              <b className="price">CHF {salePriceTotal.toFixed(2)}</b>
            </div>}
          </>}

        {!archived && changeable &&
          <div className="d-flex justify-content-between">
            <div className="btn-group quantity-control" role="group">
              <button
                className="btn btn-outline-secondary"
                disabled={quantity <= 1}
                onClick={() => dispatch(addToCurrentCart({
                  wineId: product.objectID,
                  quantity: -1,
                }))}
              >
                -
              </button>
              <button className="btn btn-outline-secondary" disabled={true}>
                {quantity}
              </button>
              <button
                className="btn btn-outline-secondary"
                onClick={() => dispatch(addToCurrentCart({
                  wineId: product.objectID,
                  quantity: 1,
                }))}
              >
                +
              </button>
            </div>
          </div>
        }
      </div>

      {changeable && <div className="delete-icon-container">
        <span onClick={() => dispatch(removeFromCurrentCart({
          wineId: product.objectID,
        }))}>
          <IconClose />
        </span>
      </div>}
    </div>
  );
};

CartItem.propTypes = {
  quantity: PropTypes.number.isRequired,
  product: PropTypes.object.isRequired,
  showImage: PropTypes.bool,
  changeable: PropTypes.bool,
  setOpen: PropTypes.func,
  archived: PropTypes.bool,
};

export default CartItem;
