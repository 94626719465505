import React from 'react';
import HeaderUrl from './AboutPageAssets/header.png';
import Image2Url from './AboutPageAssets/image2.png';
import Image3Url from './AboutPageAssets/image3.png';
import SM1Url from './AboutPageAssets/sm1.png';
import SM2Url from './AboutPageAssets/sm2.png';
import SM3Url from './AboutPageAssets/sm3.png';
import SM4Url from './AboutPageAssets/sm4.png';
import SM5Url from './AboutPageAssets/sm5.png';
import SM6Url from './AboutPageAssets/sm6.png';
import SMAllUrl from './AboutPageAssets/sm_all.png';
import { ReactComponent as IconMail } from '../icons/mail.svg';

import './AboutPage.scss';

import {FormattedMessage} from 'react-intl';

const AboutPage = () => {
  const salesManagers = [
    {
      imageUrl: SM1Url,
      name: "Carl Bertholier",
      role: "Sales Manager VD/VS",
      email: "Carl.bertholier@fgg.ch"
    },
    {
      imageUrl: SM2Url,
      name: "Julien Raymond",
      role: "Sales Manager GE",
      email: "julien.raymond@fgg.ch"
    },
    {
      imageUrl: SM3Url,
      name: "Ludovic Taurisson",
      role: "Sales Manager FR/NE/JU",
      email: "Ludovic.taurisson@fgg.ch"
    },
    {
      imageUrl: SM4Url,
      name: "Janine Brunner",
      role: "Sales Manager ZH/SG/TG",
      email: "Janine.brunner@fgg.ch"
    },
    {
      imageUrl: SM5Url,
      name: "Sascha Krummenacher",
      role: "Sales Manager LU/NW/OW/SZ/UR",
      email: "Sascha.krummenacher@fgg.ch"
    },
    {
      imageUrl: SM6Url,
      name: "Kevin Schori",
      role: "Sales Manager BE/SO",
      email: "Kevin.schori@fgg.ch"
    },
  ];

  return (
    <div className="container-fluid about-page">
      <div className="row justify-content-center gap-5">
        <div className="col-md-9">
          <div className="hero" style={{backgroundImage: `url(${HeaderUrl})`}}>
            <h1>
              <FormattedMessage
                id="AboutPage.title"
                values={{ts: Date.now()}}
              />
            </h1>
          </div>
        </div>


        <div className="col-md-9 mt-2">
          <div className="row d-flex flex-column flex-md-row img-sect">
            <div className="section col-12 col-md-6">
              <h1>
                <FormattedMessage id="AboutPage.wineFor" />
              </h1>
              <div className="text">
                <FormattedMessage id="AboutPage.wineFor.text" />
              </div>
              <h2 className='exclusiv-gap'>
                <FormattedMessage id="AboutPage.exclusiv" />
              </h2>
              <h3>
                <FormattedMessage id="AboutPage.exclusiv.subheader" />
              </h3>
              <div className="text">
                <FormattedMessage id="AboutPage.exclusiv.text" />
              </div>
            </div>

            <div className="section col-12 col-md-5">
              <img src={Image2Url} />
            </div>
          </div>
        </div>


        <div className="col-md-9">
          <div className="row d-flex flex-column-reverse flex-md-row img-sect">
            <div className="section col-12 col-md-5">
              <img src={Image3Url} />
            </div>
            <div className="section col-12 col-md-6">
              <h2>
                <FormattedMessage id="AboutPage.select.header" />
              </h2>
              <h3>
                <FormattedMessage id="AboutPage.select.subheader" />
              </h3>
              <div className="text">
                <FormattedMessage id="AboutPage.select.text" />
              </div>
            </div>
          </div>
        </div>


        <div className="col-md-9">
          <h2 className="experts-header">
            <FormattedMessage id="AboutPage.experts" />
          </h2>
          <div className='row row-cols-1 row-cols-md-3'>
            {salesManagers.map((sm,i)=><div className='col sm' key={i}>
              <img src={sm.imageUrl} />
              <div className='name'>{sm.name}</div>
              <div className='role'>{sm.role}</div>
              <div className='email'>
                <IconMail />
                {sm.email}
              </div>
            </div>)}
          </div>
        </div>


        <div className="col-md-9 sm">
          <img src={SMAllUrl} />
          <div className='name'>Einkauf, Category Management & Trade Marketing</div>
          <div className='email'>
            <IconMail />
            info@navino.ch
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutPage;
