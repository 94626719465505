import React from 'react';
import './Footer.scss';

import {FormattedMessage} from 'react-intl';
import AppStoreLinks from './AppStoreLinks';

const Navbar = () => {
  return (
    <div className="footer mt-3 p-4">
      <div className="container-xxl">
        <div className="row">
          <div className="col-md-4">
            <h4>
              <FormattedMessage
                id="Footer.Kontakt"
                values={{ts: Date.now()}}
              />
            </h4>
            <div>
              Navino<br/>
              Theophil-Roniger-Strasse 23<br/>
              CH-4310 Rheinfelden<br/>
              +41 (0) 848 125 000
            </div>
          </div>
          <div className="col-md-4">
            <h4>
              <FormattedMessage
                id="Footer.Kundenservice"
                values={{ts: Date.now()}}
              />
            </h4>
            <div>
              <a href="https://www.fscockpit.ch/" target="_blank" rel="noreferrer">
                FS.Cockpit
              </a><br/>
              Telesales: +41 (0) 848 805 010<br/>
              info@bottle.ch<br/>
              <div className="d-flex justify-content-between">
                <span>
                  <FormattedMessage
                    id="Footer.Kundenservice.MoFr"
                    values={{ts: Date.now()}}
                  />
                </span>
                <span>07.30-17.30</span>
              </div>
              <div className="d-flex justify-content-between">
                <span>
                  <FormattedMessage
                    id="Footer.Kundenservice.SaSo"
                    values={{ts: Date.now()}}
                  />
                </span>
                <span>
                  <FormattedMessage
                    id="Footer.Kundenservice.geschlossen"
                    values={{ts: Date.now()}}
                  />
                </span>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <h4>
              <FormattedMessage
                id="Footer.TheBottle.title"
                values={{ts: Date.now()}}
              />
            </h4>
            <div className="mb-3">
              <FormattedMessage
                id="Footer.TheBottle.subtitle"
                values={{ts: Date.now()}}
              />
            </div>
            <div className="footer-app-store-links">
              <AppStoreLinks />
            </div>
          </div>
          <div className="col-md-4 smaller">
            <div>
              <a href="https://webpolicies.ch/?ref=bottle.ch" target="_blank" rel="noreferrer">
                <FormattedMessage
                  id="Footer.links.nutzbed"
                  values={{ts: Date.now()}}
                />
              </a><br/>
              <a href="https://webpolicies.ch/datapolicy?ref=bottle.ch" target="_blank" rel="noreferrer">
                <FormattedMessage
                  id="Footer.links.datenschutz"
                  values={{ts: Date.now()}}
                />
              </a><br/>
              <a href="https://webpolicies.ch/cookies-bottle?ref=bottle.ch" target="_blank" rel="noreferrer">
                <FormattedMessage
                  id="Footer.links.cookie"
                  values={{ts: Date.now()}}
                />
              </a><br/>
              <a href="https://webpolicies.ch/acceptable-use-policy?ref=bottle.ch" target="_blank" rel="noreferrer">
                <FormattedMessage
                  id="Footer.links.nutzhin"
                  values={{ts: Date.now()}}
                />
              </a>
            </div>
            <div className="text-muted">
              <FormattedMessage
                id="Footer.links.verant"
                values={{
                  ts: Date.now(),
                  breakingLine: <br/>
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
