/* eslint-disable react/display-name */
import React, {forwardRef, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import CartItem from 'components/general/Cart/CartItem';
import { useDispatch, useSelector } from 'react-redux';
import { userSelectors } from 'features/userSlice';
import DatePicker from 'react-datepicker';
import { cartActions, cartSelectors, fetchDeliveryDates } from 'features/cartSlice';

import {FormattedMessage} from 'react-intl';
import Modal from '../Modal';
import { ReactComponent as IconInfo } from 'components/icons/info.svg';
import { ReactComponent as IconCalendar } from 'components/icons/big-calendar.svg';
import {ReactComponent as IconClose} from 'components/icons/close.svg';
import LoadingBottle from '../LoadingBottle';

import { browserName } from 'react-device-detect';

import { registerLocale } from  'react-datepicker';
import de from 'date-fns/locale/de';
import fr from 'date-fns/locale/fr';
import it from 'date-fns/locale/it';
import OutletSwitcherList from '../OutletSwitcherList';
import Collapsible from '../Collapsible';
import ShipToSwitcherList from '../ShipToSwitcherList';

const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
  <button className="example-custom-input" onClick={onClick} ref={ref}>
    <IconCalendar className="me-2" />
    {value || <LoadingBottle />}
    <span className="ms-auto choose">
      <FormattedMessage
        id="CartBody.Auswählen"
      />

    </span>
  </button>
));

ExampleCustomInput.propTypes = {
  value: PropTypes.any,
  onClick: PropTypes.any,
};

const CartBodyConfirm = ({
  hasItems,
  items,
  products,
  total,
  deposit,
  modalBodyRef,
}) => {
  const dispatch = useDispatch();
  const soldTo = useSelector(userSelectors.soldTo);
  const soldToOutlet = useSelector(userSelectors.soldToOutlet);
  const shipTos = useSelector(userSelectors.shipToList);
  const shipToAddress = useSelector(userSelectors.shipToAddress);
  const outlets = useSelector(userSelectors.outlets);
  const deliveryDate = useSelector(cartSelectors.deliveryDate);
  const deliveryDates = useSelector(cartSelectors.deliveryDates);
  const fetchDeliveryDatesRequest = useSelector(cartSelectors.fetchDeliveryDatesRequest);
  const [internalComment, setInternalComment] = useState('');
  // const comment = useSelector(cartSelectors.comment);
  const [internalDeliveryDate, setInternalDeliveryDate] = useState(
    deliveryDate ? new Date(deliveryDate.deliveryDate) : deliveryDates ? new Date(deliveryDates[0].deliveryDate) : null
  );
  const isDeliveryDate = (date) => {
    const deliveryDatesArray = deliveryDates.map(item => item.deliveryDate);
    const found = deliveryDatesArray.find(item => new Date(item).toDateString() == date.toDateString());
    return found != null;
  };

  const [openMBW, setOpenMBW] = useState(false);
  const [openDD, setOpenDD] = useState(false);

  useEffect(() => {
    if(fetchDeliveryDatesRequest.status === 'idle') {
      dispatch(fetchDeliveryDates({soldTo: soldTo}));
    }
    if(fetchDeliveryDatesRequest.status === 'success' && deliveryDate === null) {
      dispatch(cartActions.setDeliveryDate({deliveryDate: deliveryDates[0]}));
      setInternalDeliveryDate(new Date(deliveryDates[0].deliveryDate));
    }
  }, [fetchDeliveryDatesRequest.status]);

  const handleDeliveryDateChange = (date) => {
    const tmp = deliveryDates.find(item =>
      new Date(item.deliveryDate).toDateString() == date.toDateString()
    );

    setInternalDeliveryDate(date);
    dispatch(cartActions.setDeliveryDate({deliveryDate: tmp}));
  };

  const handleOpenDatePicker = () => {
    modalBodyRef.current.style.overflowY = 'initial';
  };

  const handleCloseDatePicker = () => {
    modalBodyRef.current.style.overflowY = 'scroll';
  };

  const handleCommentChange = (input) => {
    const tmp = input.substring(0, 200);
    setInternalComment(tmp);
    dispatch(cartActions.setComment({comment: tmp}));
  };

  const language = useSelector(userSelectors.language);
  registerLocale('de', de);
  registerLocale('fr', fr);
  registerLocale('it', it);

  const [outletSwitcherOpen, setOutletSwitcherOpen] = useState(false);
  const [shipToSwitcherOpen, setShipToSwitcherOpen] = useState(false);

  return (
    <div className="p-3">
      <div className="">
        <FormattedMessage
          id="CartBody.Rechnung"
          values={{ts: Date.now()}}
        />
        <br />
        &nbsp;
        {outlets?.length > 1 ?
          <div className="cursor-pointer">
            <Collapsible
              open={outletSwitcherOpen}
              setOpen={setOutletSwitcherOpen}
              toggleIcon={<>
                {outletSwitcherOpen ? <></> : <i style={{color: '#666'}}>ändern</i>}
              </>}
              title={<>
                {outletSwitcherOpen ?
                  <>
                    &nbsp; <br />
                    <i>Bitte neue Rechnungsadresse wählen</i>&nbsp; <br />
                    &nbsp;
                  </>
                  :
                  <b>
                    {soldToOutlet.name}, <br />
                    {soldToOutlet.street}, <br />
                    {soldToOutlet.zip} {soldToOutlet.city}
                  </b>}
              </>}
              body={
                <div onClick={() => setOutletSwitcherOpen(false)}>
                  <OutletSwitcherList className="padding-left-small" />
                </div>
              }
            />
          </div>
          :
          <div>
            <b>
              {soldToOutlet.name}, <br />
              {soldToOutlet.street}, <br />
              {soldToOutlet.zip} {soldToOutlet.city}
            </b>
          </div>
        }
      </div>

      <hr />

      <div className="">
        <FormattedMessage
          id="CartBody.Lieferung"
          values={{ts: Date.now()}}
        />
        <br />
        &nbsp;
        {shipTos?.length > 1 ?
          <div className="cursor-pointer">
            <Collapsible
              open={shipToSwitcherOpen}
              setOpen={setShipToSwitcherOpen}
              toggleIcon={<>
                {shipToSwitcherOpen ? <></> : <i style={{color: '#666'}}>ändern</i>}
              </>}
              title={<>
                {shipToSwitcherOpen ?
                  <>
                          &nbsp; <br />
                    <i>Bitte neue Lieferadresse wählen</i>&nbsp; <br />
                          &nbsp;
                  </>
                  :
                  <b>
                    {shipToAddress.name}, <br />
                    {shipToAddress.street}, <br />
                    {shipToAddress.zip} {shipToAddress.city}
                  </b>}
              </>}
              body={
                <div onClick={() => setShipToSwitcherOpen(false)}>
                  <ShipToSwitcherList className="padding-left-small" />
                </div>
              }
            />
          </div>
          :
          <div>
            <b>
              {shipToAddress.name}, <br />
              {shipToAddress.street}, <br />
              {shipToAddress.zip} {shipToAddress.city}
            </b>
          </div>
        }
      </div>

      <hr />


      <div className="">
        <FormattedMessage
          id="CartBody.Liefertermin"
          values={{ts: Date.now()}}
        />
        <br />
        &nbsp;
        <div className={browserName === 'Safari' ? 'safari' : ''}>
          <DatePicker
            selected={internalDeliveryDate}
            filterDate={isDeliveryDate}
            onChange={(date) => handleDeliveryDateChange(date)}
            dateFormat="dd.MM.yyyy"
            onCalendarOpen={() => handleOpenDatePicker()}
            onCalendarClose={() => handleCloseDatePicker()}
            locale={language}
            calendarStartDay={1}
            customInput={<ExampleCustomInput />}
            withPortal />
        </div>
      </div>

      <hr />

      <div className="checkout-items">
        <div className="header">
          <FormattedMessage
            id="CartBody.Bestellübersicht"
            values={{ts: Date.now()}}
          />
        </div>
        <br />
        &nbsp;
        {hasItems ? items.map(item => (
          <div key={item.id} className="small-product mb-4">
            {products.find(product => product && product.objectID == item.wineId) ?
              <CartItem
                quantity={item.quantity}
                product={products.find(product => product.objectID == item.wineId)}
                showImage={false}
                changeable={false}
                archived={item && item.issues.includes('ARCHIVED')}
              />
              : 
              <div className="d-flex justify-content-between align-items-center">
                <LoadingBottle />
              </div>
            }
          </div>
        )) : 
          <i>
            <FormattedMessage
              id="CartBody.NoProducts"
              values={{ts: Date.now()}}
            />
            
          </i>
        }
        <hr />
        <div className="d-flex justify-content-between">
          <span>
            <FormattedMessage
              id="CartBody.Zwischensumme"
              values={{ts: Date.now()}}
            />

          </span>
          <span className="price">
            CHF {total.toFixed(2)}
          </span>
        </div>
        <hr />
        <div className="d-flex justify-content-between">
          <span>
            <FormattedMessage
              id="Cart.Gebindepfand"
              values={{ts: Date.now()}}
            />
          </span>
          <span className="price">CHF {deposit.toFixed(2)}</span>
        </div>
        <div className="d-flex justify-content-between align-items-center mt-2">
          <span className="d-flex align-items-center">
            <FormattedMessage
              id="Cart.MBW"
              values={{ts: Date.now()}}
            />
            <IconInfo onClick={() => setOpenMBW(true)} />
            <Modal
              disableBodyScrollOnOpen={false}
              name="mbw info"
              open={openMBW}
              setOpen={setOpenMBW}
              position="middle-side"
              size="tiny"
              parentBody={modalBodyRef}
              title={<>
                <IconClose className="icon ms-auto" onClick={() => setOpenMBW(false)}/>
              </>}
              body={<div className="d-flex flex-column justify-content-center align-items-center text-center px-5">
                <IconInfo style={{height: '50px', width: '50px'}} />
                <h6 className="my-4">
                  <FormattedMessage
                    id = "Cart.MBW.Popup.Title"

                  />
                </h6>
                <p>
                  <FormattedMessage
                    id="Cart.MBW.Popup"
                  />
                </p>
              </div>}
            />
          </span>
          <span className="price">CHF {(total >= 300 ? 0 : 25).toFixed(2)}</span>
        </div>
        {total < 300 && <>
          <span className="red">
            <FormattedMessage
              id="CartBody.FehlenCHF"

            />
            &nbsp;{(300 - total).toFixed(2)}&nbsp;
            <FormattedMessage
              id="CartBody.ZuschlagEntfällt"

            />
          </span>
        </>}
        <div className="d-flex justify-content-between mt-2">
          <span className="d-flex align-items-center">
            <FormattedMessage
              id="CartBody.Lieferkostenpauschale"
              values={{ts: Date.now()}}
            />
            {deliveryDate && deliveryDate.fee > 10 && <>
              <IconInfo onClick={() => setOpenDD(true)} />
            </>}
            <Modal
              disableBodyScrollOnOpen={false}
              name="info delivery date"
              open={openDD}
              setOpen={setOpenDD}
              position="middle-side"
              size="tiny"
              parentBody={modalBodyRef}
              title={<>
                <IconClose className="icon ms-auto" onClick={() => setOpenDD(false)}/>
              </>}
              body={<div className="d-flex flex-column justify-content-center align-items-center text-center px-5">
                <IconInfo style={{height: '50px', width: '50px'}} />
                <h6 className="my-4">
                  <FormattedMessage
                    id="CartBody.Lieferkostenpauschale"
                  />
                </h6>
                <p>
                  <FormattedMessage
                    id="CartBody.MorgenLiefertag"
                  />

                </p>
              </div>}
            />
          </span>
          <span className="price">CHF {(deliveryDate ? deliveryDate.fee : 0).toFixed(2)}</span>
        </div>
        {deliveryDate && deliveryDate.fee > 10 && <>
          <span className="red">
            <FormattedMessage
              id="Cart.BestellungFolgetag"
            />
          </span>
        </>}
        <hr />
        <div className="d-flex justify-content-between">
          <span>
            <FormattedMessage
              id="CartBody.Total"
              values={{ts: Date.now()}}
            />

          </span>
          <span className="total price">
            CHF {(total + deposit + (total >= 300 ? 0 : 25) + 10).toFixed(2)}
          </span>
        </div>
        <div className="mt-3">
          <div className="text-muted">
            <FormattedMessage
              id="CartBody.Kommentar"
            />
          </div>
          <textarea value={internalComment} onChange={(ev) => handleCommentChange(ev.target.value)} />
          <div className="text-muted d-flex justify-content-end">
            <span>{internalComment?.length}
              <FormattedMessage
                id="CartBody.Zeichen"
              />

            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

CartBodyConfirm.propTypes = {
  hasItems: PropTypes.bool.isRequired,
  items: PropTypes.array,
  products: PropTypes.array.isRequired,
  total: PropTypes.number.isRequired,
  deposit: PropTypes.number.isRequired,
  modalBodyRef: PropTypes.any,
};

export default CartBodyConfirm;