import React from 'react';

import { Route, Switch } from 'react-router';
import {
  HashRouter,
} from 'react-router-dom';

import {useSelector} from 'react-redux';
import {userSelectors} from 'features/userSlice';

import BottomBar from './components/general/BottomBar.js';
import Footer from './components/general/Footer.js';
import LoginPage from './components/pages/LoginPage.js';
import RegisterPage from './components/pages/RegisterPage';
import Navbar from './components/general/Navbar.js';
import OutletSwitcherModal from 'components/general/OutletSwitcherModal';
import ProductDetailPage from './components/pages/ProductDetailPage.js';
import ProductListPage from './components/pages/ProductListPage.js';
import StartPage from './components/pages/StartPage.js';

import { positions, Provider } from 'react-alert';
import AlertTemplate from 'components/general/Alert';
import CollectionsPage from 'components/pages/CollectionsPage.js';
import AboutPage from 'components/pages/AboutPage.js';
import OfferPage from 'components/pages/OfferPage.js';

import {IntlProvider} from 'react-intl';

import messages from './messages.json';
import ScrollToTop from 'components/general/ScrollToTop.js';

import { isIE } from 'react-device-detect';
import FavoritesPage from 'components/pages/FavoritesPage.js';
import {CustomerSwitcherModal} from './components/general/CustomerSwitcherModal';
import ShipToSwitcherModal from './components/general/ShipToSwitcherModal';
import { SVGDefs } from 'components/general/SVGDefs.js';

const options = {
  timeout: 5000,
  position: positions.TOP_CENTER,
  offset: '0',
  transition: 'scale',
  containerStyle: {
    // justifyContent: 'stretch',
    // backgroundColor: 'yellow',
  }
};

function App() {
  const language = useSelector(userSelectors.language);
  const choosenMessages = Object.keys(messages).reduce((acc, key) => {
    acc[key] = messages[key][language] || messages[key]['de'];
    return acc;
  }, {});

  if (isIE) return (
    <div>
      <h1>Willkommen auf der Navino Homepage.</h1>
      <p>Sie benutzen einen veralteten Browser. Damit wir ein sicheres und benutzerfreundliches Benutzererlebnis geben können, benutzen Sie bitte einen modernen Browser wie: Chrome, Firefox, Safari, Opera, oder Edge.</p>
    </div>
  );

  return (
    <IntlProvider messages={choosenMessages} locale={language} defaultLocale="de">
      <Provider template={AlertTemplate} {...options}>
        <HashRouter>
          <ScrollToTop />
          <div className="App">
            <SVGDefs />
            <div className="container-xxl">
              <Navbar/>
              <Switch>
                <Route exact path="/" component={StartPage}/>
                <Route path="/login" component={LoginPage}/>
                <Route path="/register" component={RegisterPage}/>
                <Route
                  path="/products/all/:startRecommendation?"
                  render={(props) => <ProductListPage {...props} listPageType="all" />}
                />
                <Route
                  path="/products/exclusive/:startRecommendation?"
                  render={(props) => <ProductListPage {...props} listPageType="exclusive" />}
                />
                <Route
                  path="/products/selection/:startRecommendation?"
                  render={(props) => <ProductListPage {...props} listPageType="nonExclusive" />}
                />
                <Route path="/product/:id" component={ProductDetailPage}/>
                <Route path="/offers" component={OfferPage}/>
                <Route path="/collections" component={CollectionsPage}/>
                <Route path="/favorites" component={FavoritesPage}/>
                <Route path="/about" component={AboutPage}/>
              </Switch>
            </div>
            <BottomBar/>
            <Footer />
            <CustomerSwitcherModal />
            <OutletSwitcherModal />
            <ShipToSwitcherModal />
          </div>
        </HashRouter>
      </Provider>
    </IntlProvider>
  );
}

export default App;
