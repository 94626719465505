import axios from 'axios';
import {identity, mergeDeepRight, path} from 'ramda';
import {authActions, authSelectors} from '../features/authSlice';

const fsCockpitBaseUrl = process.env.REACT_APP_FS_COCKPIT_BASE_URL;
//const fsCockpitBaseUrl = "https://fs-cockpit-test.test-kub.fs.cust.sobr-brews.ch/fgg/api/v0";

const fsCockpitClient = axios.create({
  baseURL: fsCockpitBaseUrl
});

const authedFsCockpitClient = axios.create({
  baseURL: fsCockpitBaseUrl
});

/**
 * we can't just import redux store here because that creates circular dependencies.
 *
 * -> needs to be initialized in index.js after store is created for the first time.
 */
export function initAuthedFsCockpitClient(reduxStore) {
  authedFsCockpitClient.interceptors.request.use(
    config => {
      const accessToken = authSelectors.accessToken(reduxStore.getState());
      if (!accessToken) {
        return Promise.reject('no access token');
      }
      return mergeDeepRight(config, {headers: {'Authorization': `Bearer ${accessToken}`}});
    }
  );

  const CUSTOM_RETRY_MARKER = '_waRetried';
  const RETRYABLE_STATUS_CODES = new Set([401, 403]);
  authedFsCockpitClient.interceptors.response.use(
    identity,
    failedRequest => {
      const retryableStatusCode = RETRYABLE_STATUS_CODES.has(path(['response', 'status'], failedRequest));
      const alreadyRetried = path(['config', CUSTOM_RETRY_MARKER], failedRequest);
      if (!retryableStatusCode || alreadyRetried) {
        return Promise.reject(failedRequest);
      }

      return reduxStore.dispatch(authActions.refresh())
        .then(() => {
          const newConfig = Object.assign({}, failedRequest.response.config, {CUSTOM_RETRY_MARKER: true});
          return authedFsCockpitClient.request(newConfig);
        });
    }
  );
}

export {fsCockpitClient as fsCockpitClient};
export {authedFsCockpitClient as authedFsCockpitClient};