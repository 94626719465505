import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {authSelectors} from '../../features/authSlice';
import Modal from 'components/general/Modal';
import {ReactComponent as IconLogo} from '../icons/info.svg';
import {FormattedMessage, useIntl} from 'react-intl';
import {authActions} from '../../features/authSlice';
import {ReactComponent as IconArrowLeft} from '../icons/arrow-left.svg';
import {reset} from '../../features/searchSlice';
import {recommendationActions} from '../../features/recommendationSlice';
import './CustomerSwitcherModal.scss';
import LoadingBottle from './LoadingBottle';
import {checkCustomerInput, choseCustomerActions, choseCustomerSelectors} from '../../features/choseCustomerSlice';
import {userActions, userSelectors} from '../../features/userSlice';

export function CustomerSwitcherModal() {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const isLoggedIn = useSelector(authSelectors.loggedIn);
  const isSales = useSelector(authSelectors.isSalesUser);
  const customer = useSelector(authSelectors.customer);
  const soldTo = useSelector(userSelectors.soldTo);
  const customerPrev = useSelector(choseCustomerSelectors.customerPrev);
  const shipToPrev = useSelector(userSelectors.shipToPrev);
  const customerInputValue = useSelector(choseCustomerSelectors.customer);
  const checkCustomerInputRequest = useSelector(choseCustomerSelectors.checkCustomerInputRequest);
  const setCustomerInputValue = (customer) => dispatch(choseCustomerActions.setCustomer(customer));
  const intl = useIntl();

  const handleCancel = () => {
    if (customerPrev == null || shipToPrev == null) {
      dispatch(authActions.setCustomer({customer: '900000000'}));
      dispatch(userActions.setSoldTo('900000000'));
      dispatch(checkCustomerInput(
        {
          soldTo: '900000000'
        }
      )).then((data) => {
        if (data.payload !== undefined) {
          dispatch(userActions.setOutlets(data.payload));
          dispatch(userActions.setShipTo('900000000'));
        }
      });
      dispatch(reset());
      dispatch(choseCustomerActions.reset());
      dispatch(recommendationActions.resetPlz());
      setCustomerInputValue('');
    } else {
      dispatch(authActions.setCustomer({customer: customerInputValue}));
      dispatch(userActions.setSoldTo(customerInputValue));
    }
  };
  
  const handleConfirm = () => {
    setCustomerInputValue(customerInputValue);
    dispatch(checkCustomerInput(
      {
        soldTo: customerInputValue
      }
    )).then((data) => {
      if (data.payload !== undefined) {
        dispatch(authActions.setCustomer({customer: customerInputValue}));
        dispatch(userActions.setSoldTo(customerInputValue));
        dispatch(userActions.setShipTo(null));
        dispatch(userActions.setOutlets(data.payload));
        dispatch(userActions.fetchShipTos({soldTo:customerInputValue}));
      }
    });
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleConfirm();
    }
  };

  useEffect(() => {
    setOpen(isLoggedIn && isSales === true && (soldTo == null || customer == null));}, [isLoggedIn, open, soldTo, customer]);

  return (
    <>
      <Modal
        name="customer switcher"
        open={open}
        setOpen={setOpen}
        closeable={false}
        position="middle-side"
        title={<div className="cursor-pointer" onClick={() => handleCancel()}>
          <IconArrowLeft className="me-3" />
            Abbrechen
        </div>}
        body={ <div className="customer-switcher">
          <IconLogo />
          <p>
            <FormattedMessage
              id="CustomerSwitcher.Info"
            />
          </p>
          {(checkCustomerInputRequest.loading === true) ?
            <LoadingBottle />
            :
            <div className='d-flex justify-content-center p-1'>
              <input
                className="input-item"
                type="text"
                value={customerInputValue || ''}
                onKeyDown={(event) => handleKeyDown(event)}
                onChange={e => setCustomerInputValue(e.target.value)}
                placeholder={intl.formatMessage({id: 'CustomerSwitcher.Kundennummer'})}
              />
            </div>
          }
          {checkCustomerInputRequest.error !== null ?
            <div className="error">{intl.formatMessage({id: 'CustomerSwitcher.KorrekteKundennummer'})}</div> : <div></div>
          }

          <button
            type="submit"
            onClick={() => handleConfirm()}
          >
            <FormattedMessage
              id="CustomerSwitcher.Confirm"
              values={{ts: Date.now()}}
            />
          </button>
          
        </div>}
      />
    </>
  );
}

export default CustomerSwitcherModal;