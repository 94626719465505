import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Highlight, connectRefinementList } from 'react-instantsearch-dom';
import './CustomRefinementList.scss';
import { ReactComponent as IconSearch } from 'components/icons/search.svg';
import {ReactComponent as IconCircle} from 'components/icons/circle.svg';
import { FormattedMessage } from 'react-intl';

function RefinementList ({
  items,
  isFromSearch,
  refine,
  searchForItems,
  createURL,
  title,
  showMore,
  limit,
  // showMoreLimit,
  searchable,
  // translation,
  // extended,
  isWinetype = false,
  unit = '',
  tansformLabel = null,
}) {
  const [extended, setExtended] = useState(false);

  // const isRefined = items.filter(item => item.isRefined).length > 0;

  return (
    <div className="refinement list-group list-group-flush">
      {searchable &&
      <div className="list-group-item list">
        <div className="form-with-icon">
          <input
            type="search"
            className="form-control rounded-pill"
            placeholder={'Nach ' + title + ' suchen'}
            onChange={event => searchForItems(event.currentTarget.value)}
          />
          <IconSearch />
        </div>
      </div>}
      {items.length > 0 ?
        items.map((item, i) => (!showMore || i < limit || extended) && (
          <a
            key={item.label}
            href={createURL(item.value)}
            className="list-group-item list-group-item-action d-flex justify-content-between list item"
            style={{ fontWeight: item.isRefined ? 'bold' : '' }}
            onClick={event => {
              event.preventDefault();
              refine(item.value);
            }}
          >
            <div className="d-flex align-items-center">
              <input
                type="checkbox"
                className="form-check-input me-2"
                checked={item.isRefined}
                onChange={event => {
                  event.preventDefault();
                  refine(item.value);
                }}
              />
              {isWinetype && <div className="wine-type-icon">
                <IconCircle className={item.label} />
              </div>}
              {isFromSearch ? (
                <Highlight attribute="label" hit={item} />
              ) : (
                <>
                  {!(tansformLabel && typeof tansformLabel === 'function') && item.label}
                  { (tansformLabel && typeof tansformLabel === 'function') && tansformLabel(item.label)}
                </>
              )}{unit}
            </div>
            <div>({item.count})</div>
          </a>
        ))
        :
        <div className="list-group-item text-center">
          <i>
            <FormattedMessage
              id="Refinement.NoSelection"
            />

          </i>
        </div>
      }
      {(showMore && items.length > limit) && (
        <button
          className="list-group-item list-group-item-action text-center list"
          onClick={() => {
            setExtended(!extended);
          }}
        >
          <i>{extended ? 'show less': 'show more'}</i>
        </button>
      )}
    </div>
  );
}

RefinementList.propTypes = {
  items: PropTypes.array.isRequired,
  isFromSearch: PropTypes.bool,
  refine: PropTypes.func.isRequired,
  searchForItems: PropTypes.func.isRequired,
  createURL: PropTypes.func.isRequired,
  title: PropTypes.string,
  showMore: PropTypes.bool,
  limit: PropTypes.number,
  showMoreLimit: PropTypes.number,
  searchable: PropTypes.bool,
  translation: PropTypes.object,
  isWinetype: PropTypes.bool,
  unit: PropTypes.string,
  tansformLabel: PropTypes.any,
};

export const CustomRefinementList = connectRefinementList(RefinementList);